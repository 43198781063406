// 获取用户信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;
// 获取最近的议题/投票列表
const getLastVoteListURL = `/gateway/hc-govern/mini/ownersVote/getLastVoteList`;
// 获取议题列表
const getPropositionListURL = `/gateway/hc-govern/mini/ownersVote/propositionList`;
// 获取我的议题列表
const getMyPropositionListURL = `/gateway/hc-govern/mini/ownersVote/myPropositionList`;
// 新增议题/议题上会, 议题转化成业主大会
const addInfoURL = `/gateway/hc-govern/mini/ownersVote/addInfo`;
// 议题/议题上会/业主大会详情
const getDetailURL = `/gateway/hc-govern/mini/ownersVote/propositionDetails`;
// 上传签名
const uploadURL = `/gateway/blade-resource/oss/endpoint/put-file`;

// 校验身份
const checkIdentityURL = `/gateway/hc-govern/mini/ownersVote/checkIdentity`;
// 获取投票厅/业主大会列表,包括议题上会列表
const voteListURL = `/gateway/hc-govern/mini/ownersVote/voteList`;
// 议题上会支持/反对
const supportOrOpposeSubjectURL = `/gateway/hc-govern/mini/ownersVote/supportOrOpposeSubject`;
// 获取弹窗信息
const getVoteLawURL = `/gateway/hc-govern/mini/ownersVote/getVoteLaw`;
// 首页弹框-确认提交按钮 选择是否提示投票法规
const checkSubmitURL = `/gateway/hc-govern/mini/ownersVote/choiceIsRemind`;
// 选项投票
const voteSubmitURL = `/gateway/hc-govern/mini/ownersVote/voteSubmit`;
// 获取投票结果
const getVoteResultURL = `/gateway/hc-govern/mini/ownersVote/getVoteResult`;
// 点踩
// const cancelPraiseURL = `/gateway/x`;
// 点赞
// const addPraiseURL = `/gateway/xx`;
// 获取投票列表
const getVoteURL = `/gateway/xxxx`;

export {
  userInfoURL,
  getLastVoteListURL,
  getPropositionListURL,
  getMyPropositionListURL,
  addInfoURL,
  getDetailURL,
  uploadURL,
  checkIdentityURL,
  voteListURL,
  supportOrOpposeSubjectURL,
  getVoteLawURL,
  checkSubmitURL,
  voteSubmitURL,
  getVoteURL,
  getVoteResultURL,
  // cancelPraiseURL,
  // addPraiseURL,
};
