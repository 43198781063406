<template>
  <div class="initiateVote">
    <div class="section-form">
      <div class="section-item">
        <div class="section-item--label">姓名</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.userName"
            disabled
            :maxlength="20"
            inputAlign="right"
            placeholder="请填写姓名"
          ></v-input>
        </div>
      </div>
      <div class="section-item radio">
        <div class="section-item--label">是否匿名发布</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="cryptonymOps"
            :value.sync="form.isAnonymity"
          ></v-radio-group>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">议题标题</div>
        <div class="section-item--control">
          <v-input
            disabled
            v-model.trim="form.title"
            inputAlign="right"
            placeholder="请输入标题"
          ></v-input>
        </div>
      </div>
      <div class="section-item textarea">
        <div class="section-item--label">议题内容</div>
        <div class="section-item--control">
          <v-input
            disabled
            v-model.trim="form.content"
            placeholder="请写下您的议事内容"
            type="textarea"
            :autosize="{
              minHeight: 160,
              maxHeight: 160,
            }"
            :maxlength="500"
            :showWordLimit="true"
          ></v-input>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">议题图片</div>
        <div class="section-item--control">
          <div class="upload">
            <!-- <v-upload
              disabled
              :imgUrl.sync="form.imageUrls"
              :activityPicture.sync="form.imageUrls"
              :showUpload="false"
              :deletable="false"
              ref="upload"
              :maxCount="9"
            ></v-upload> -->
            <vote-pic-list
              :picList="form.imageUrls ? form.imageUrls.split(',') : []"
            ></vote-pic-list>
            <p v-if="!form.imageUrls">注：暂无议题图片</p>
            <!-- <p>注：最多可存在9张图片</p> -->
          </div>
        </div>
      </div>

      <div class="section-item signcCanvas">
        <div class="section-item--label">
          <span>电子签名</span>
          <div class="reset" @click="handleReset">
            <span>重置</span>
          </div>
        </div>
        <div class="section-item--control">
          <div class="sign">
            <img v-if="!isShowsignCanvas" :src="form.esignature" alt="" />
            <signCanvas
              v-else
              ref="esign"
              :width="614"
              :height="242"
              :isCrop="isCrop"
              :lineWidth="lineWidth"
              :lineColor="lineColor"
              :bgColor.sync="bgColor"
            />
          </div>
        </div>
      </div>

      <div class="section-item radio">
        <div class="section-item--label">电子签名是否公开</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="publicOps"
            :value.sync="form.signatureIsOpen"
          ></v-radio-group>
        </div>
      </div>

      <div class="surebtn" @click="submitBefore">
        <div class="btn">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { base64toFile } from "@/utils/utils.js";
import { addInfoURL, getDetailURL, userInfoURL, uploadURL } from "./api";
import signCanvas from "@/components/bussiness/signCanvas";
import votePicList from "./components/picList.vue";

export default {
  name: "initiateVote",
  components: {
    signCanvas,
    votePicList,
  },
  data() {
    return {
      cryptonymOps: [
        { name: 1, value: "是" },
        { name: 0, value: "否" },
      ],
      publicOps: [
        { name: 1, value: "公开" },
        { name: 0, value: "不公开" },
      ],
      userInfo: {},
      form: {
        id: "", // 议题Id
        userName: "",
        isAnonymity: 0,
        title: "",
        content: "",
        imageUrls: "",
        esignature: "", // 签名图片
        signatureIsOpen: 0,
      },
      timer: null,
      /*  */
      isShowsignCanvas: false,
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    await this.initData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
    },
    async initData() {
      const { id } = this.$route.query;
      this.form.id = id;
      await this.getuserInfo();
      this.getDetail();
    },
    getDetail() {
      this.$axios
        .get(getDetailURL, {
          params: {
            id: this.form.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.update(res.data);
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 获取用户信息
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
        this.form.userName = this.userInfo.userName;
        this.form.esignature = this.userInfo.esignature;

        // 是否展示签名组件 - 存在图片则不显示组件
        this.isShowsignCanvas = !this.form.esignature;
      }
    },

    // 清空签名
    handleReset() {
      this.isShowsignCanvas = true;
      this.form.esignature = "";
      this.$nextTick(() => {
        this.$refs.esign.reset();
      });
    },
    submitBefore() {
      if (!this.valid()) {
        return;
      }
      if (!this.isShowsignCanvas) {
        // 已存在签名图片,直接提交
        this.submit();
      } else {
        // 未存在签名图片
        this.handleGenerate();
      }
    },
    /* signCanvas start */
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          // alert(err); // 画布没有签字时会执行这里 'Not Signned'
          // if (err == "Not Signned") {
          //   this.$toast("未签名");
          // } else {
          this.$toast(err);
          // }
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadURL, formData);
      if (res.code == 200) {
        this.form.esignature = res.data.link;
        this.submit();
      }
    },
    /** end */
    submit() {
      this.$axios
        .post(addInfoURL, {
          type: 2, // 1-议题，2-业主大会，3-议题上会
          ...this.form,
          houseId: this.houseId || this.communityId || undefined,
          optUserId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success({
              message: "投票提交成功，待业 委会、社区审核，3s后自动跳转投票厅",
              forbidClick: true,
              duration: 3000,
            });
            const _this = this;
            this.timer = setTimeout(() => {
              _this.$toast.clear();
              _this.$router.replace({
                name: "voteHall",
              });
            }, 2500);
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 验证必填项
    valid() {
      const { userName, title, content } = this.form;
      let message = undefined;
      if (userName === "") {
        message = "姓名不可为空";
      } else if (title === "") {
        message = "议题标题不可为空";
      } else if (content === "") {
        message = "议题内容不可为空";
      }
      //  else if (esignature === "") {
      //   message = "电子签名不可为空";
      // }
      //  else if (imageUrls === "") {
      //   message = "请上传议题图片";
      // }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
  },
};
</script>
<style lang="less" scoped>
.initiateVote {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  background: #f6f8fb;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: calc(
    30px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    30px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
    background: #ffffff;
    .section-item {
      display: flex;
      &:nth-child(-n + 3) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }
      .section-item--label {
        flex: 1;
        line-height: 88px;
        color: #333333;
        font-size: 30px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
      }
      .section-item--control {
        max-width: 400px;
      }
      &.radio {
        .section-item--control {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.textarea {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-cell {
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      &.upload {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          // ::v-deep .van-cell {
          //   background: rgba(0, 0, 0, 0.05);
          // }
          ::v-deep .van-uploader {
            .van-uploader__preview-image {
              width: 160px;
              height: 160px;
            }
            .van-uploader__upload {
              width: 160px;
              height: 160px;
            }
          }
          p {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 20px;
            font-weight: 400;
          }
        }
      }
      &.signcCanvas {
        display: block;
        .section-item--label {
          display: flex;
          align-items: center;
          span {
            flex: 1;
          }
          .reset {
            font-size: 28px;
            color: #0578fa;
            letter-spacing: 4px;
          }
        }
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          .sign {
            margin-bottom: 12px;
            border-radius: 16px;
            overflow: hidden;
            img {
              width: 100%;
              height: 242px;
            }
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 492px;
        height: 74px;
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 39px;
        border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}
</style>
